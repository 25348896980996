// assets
import { ReactComponent as DocumentIcon } from './icons/docs.svg'
import { ReactComponent as LeavesIcon } from './icons/leaves.svg'
import { ReactComponent as JobIcon } from './icons/jobs.svg'
import { ReactComponent as RequestIcon } from './icons/request.svg'
// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const actions = {
  id: 'actions',
  title: 'ACTIONS',
  type: 'group',
  children: [
    {
      id: 'actions-docs-invoice',
      title: 'Docs and Invoice',
      type: 'item',
      url: '/actions/docs',
      icon: DocumentIcon
    },
    {
      id: 'actions-leaves',
      title: 'Leaves',
      type: 'item',
      url: '/actions/leave',
      icon: LeavesIcon
    },
    {
      id: 'actions-jobs-careers',
      title: 'Jobs Careers',
      type: 'item',
      url: '/actions/jobs',
      icon: JobIcon
    },
    // {
    //   id: 'otheractions-clientrequest',
    //   title: 'Client Request',
    //   type: 'item',
    //   url: '/actions/clientrequest',
    //   icon: RequestIcon
    // },
    {
      id: 'actions-request-for-admin',
      title: 'Request for Admin',
      type: 'item',
      url: '/actions/requestadmin',
      icon: RequestIcon
    },
  ]
};

export default actions;
