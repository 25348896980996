// assets
import { ReactComponent as DocumentIcon } from './icons/docs.svg'
import { ReactComponent as LeavesIcon } from './icons/leaves.svg'
import { ReactComponent as JobIcon } from './icons/jobs.svg'
import { ReactComponent as RequestIcon } from './icons/request.svg'
// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const others = {
  id: 'actions',
  title: 'OTHERS',
  type: 'group',
  children: [
    {
      id: 'otheractions-docs-invoice',
      title: 'Maintain Staffs',
      type: 'item',
      url: '/actions/maintainstaffs',
      icon: DocumentIcon
    },
    {
      id: 'otheractions-leaves',
      title: 'Maintain Clients',
      type: 'item',
      url: '/actions/maintainclients',
      icon: LeavesIcon
    },   
  ]
};

export default others;
