import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - utilities
const Leaves = Loadable(lazy(() => import('pages/components-overview/Actions/Leaves')));
const Docs = Loadable(lazy(() => import('pages/components-overview/Actions/DocsAndInvoices')));
const EDocs = Loadable(lazy(() => import('pages/components-overview/EActions/DocsAndInvoices')));
const ELeave = Loadable(lazy(() => import('pages/components-overview/EActions/RequestLeave')));
const MaintainStaffs = Loadable(lazy(() => import('pages/components-overview/Actions/MaintainStaffs')));
const MaintainClients = Loadable(lazy(() => import('pages/components-overview/Actions/MaintainClients')));
const RequestAdmin = Loadable(lazy(() => import('pages/components-overview/Actions/RequestAdmin')));
const ClientRequest = Loadable(lazy(() => import('pages/components-overview/Actions/ClientRequest')));
const Job = Loadable(lazy(() => import('pages/components-overview/Actions/JobCareer')));
const CDocs = Loadable(lazy(() => import('pages/components-overview/CActions/DocsAndInvoices')));
const SDocs = Loadable(lazy(() => import('pages/components-overview/SActions/DocsAndInvoices')));
const SAdmins = Loadable(lazy(() => import('pages/components-overview/SActions/MaintainAdmins')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/actions/leave',
      element: <Leaves />
    },
    {
      path: '/actions/docs',
      element: <Docs />
    },
    {
      path: '/actions/clientrequest',
      element: <ClientRequest />
    },
    {
      path: '/actions/maintainstaffs',
      element: <MaintainStaffs />
    },
    {
      path: '/actions/maintainclients',
      element: <MaintainClients />
    },
    {
      path: '/actions/requestadmin',
      element: <RequestAdmin />
    },
    {
      path: '/actions/jobs',
      element: <Job />
    },
    {
      path: 'employees/actions/docs',
      element: <EDocs />
    },
    {
      path: 'employees/actions/leave',
      element: <ELeave />
    },
    {
      path: 'clients/actions/docs',
      element: <CDocs />
    },
    {
      path: 'superadmin/actions/docs',
      element: <SDocs />
    },
    {
      path: 'superadmin/actions/maintainadmins',
      element: <SAdmins />
    }
  ]
};

export default MainRoutes;
