// project import
import actions from "./actions";
import CActions from "./clients/actions";
import EActions from "./employees/actions";
import SActions from "./superAdmin/actions";
import menu from './menu';
import others from "./others";


// ==============================|| MENU ITEMS ||============================== //

const role = localStorage.getItem('userRole')

let menuItems;

if(role === "admin"){
  menuItems = {
    items: [menu, actions, others]
  };
} else if (role === "employee") {
  menuItems = {
    items: [EActions]
  };
} 
else if (role === "superadmin") {
  menuItems = {
    items: [SActions]
  };
}else {
  menuItems = {
    items: [CActions]
  };
}


export default menuItems;
