import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { FormControl, Input, Button, DialogActions, Snackbar, Alert } from "@mui/material";
import axios from "axios";

const ProfileForm = ({ open, onClose, emp_id }) => {
  const [imageBlobUrl, setImageBlobUrl] = useState(null);

  const [isLoaded, setIsDataLoaded] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const emp_id_int = parseInt(emp_id, 10);
  const userJson = localStorage.getItem('user');
  const user = userJson ? JSON.parse(userJson) : null;
  const userRole = localStorage.getItem('userRole');
  let userId = null;

  if (user) {
    if (userRole === 'client') {
      userId = user.client_id;
    } else if (userRole === 'employee') {
      userId = user.emp_id;
    } else if (userRole === 'admin') {
      userId = user.admin_id;
    } else if (userRole === 'superadmin') {
      userId = user.superadmin_id;
    } else {
      console.error('Unknown user role:', userRole);
    }
  }

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const userRole = localStorage.getItem('userRole');

        let apiUrl = '';

        if (userRole === 'client') {
          apiUrl = `https://api.hertzandhenry.com/clientTable.php?client_id=${userId}`;
        } else if (userRole === 'employee') {
          apiUrl = `https://api.hertzandhenry.com/employeeTable.php?emp_id=${userId}`;
        } else if (userRole === 'admin') {
          apiUrl = `https://api.hertzandhenry.com/adminTable.php?admin_id=${userId}`;
        } else if (userRole === 'superadmin') {
          apiUrl = `https://api.hertzandhenry.com/superAdminTable.php?superadmin_id=${userId}`;
        } else {
          console.error('Unknown user role:', userRole);
          return;
        }

        const response = await axios.get(apiUrl);
        setEmployeeDetails(response.data);

        setTimeout(() => {
          setIsDataLoaded(true);
        }, 2000);
      } catch (error) {
        console.error('Error fetching employee details:', error);
      }
    };

    fetchEmployeeDetails();
  }, [emp_id_int, userId]);


  const [employeeDetails, setEmployeeDetails] = useState({
    emp_id: userId,
    image: "",
  });

  const handleUserNameChange = (event) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      name: event.target.value,
    }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleUserEmailChange = (event) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      email: event.target.value,
    }));
  };

  const handleUserPasswordChange = (event) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      password: event.target.value,
    }));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleImageChange = (event) => {
    const file = event.target.files;
    console.log(file[0].name);
    const img = "https://hnhd.hertzandhenry.com/docsandinvoices/" + file[0].name
    console.log(img)
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      image: img,
    }));
    setNewImage(file[0]);
  };

  const handleEditImage = () => {
    document.getElementById("imageInput").click();
  };

  const handleRoleChange = (event) => {
    console.log(event.target.value);
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      role: event.target.value,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      phone_no: event.target.value,
    }));
  };

  const handleEditSave = async () => {
    setEditMode(!editMode);
    try {
      const file = newImage;

      const ImageData = new FormData();
      console.log(file);
      ImageData.append("file", file);

      const uploadResponse = await fetch(
        "https://hnhd.hertzandhenry.com/upload_image.php",
        {
          method: "POST",
          body: ImageData,
        }
      );

      const uploadData = await uploadResponse.json();
      console.log(uploadData);
      const serverFilePath = uploadData.filePath;
      setImageBlobUrl(`https://hnhd.hertzandhenry.com/${serverFilePath}`);

      const userRole = localStorage.getItem('userRole');
      let apiUrl = '';

      if (userRole === 'client') {
        apiUrl = "https://api.hertzandhenry.com/clientTable.php";
      } else if (userRole === 'employee') {
        apiUrl = "https://api.hertzandhenry.com/employeeTable.php";
      } else if (userRole === 'admin') {
        apiUrl = "https://api.hertzandhenry.com/adminTable.php";
      }
      else if (userRole === 'superadmin') {
        apiUrl = "https://api.hertzandhenry.com/superAdminTable.php";
      } else {
        console.error('Unknown user role:', userRole);
        return;
      }

      const postResponse = await axios.put(
        apiUrl,
        employeeDetails
      );
      console.log(postResponse);

      showSnackbar("success", "Employee Updated Sucessfully!");
      setTimeout(() => {
        setSnackbarOpen(false);
        onClose();
      }, 3000);
    } catch (error) {
      showSnackbar("error", "Error updating employee. Please try again.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "15px", maxWidth: '400px' } }}
    >
      <DialogContent sx={{ mb: 3 }}>
        {isLoaded ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                bgcolor: "#F5F5F5",
                alignItems: "center",
                borderRadius: "5px",
                mb: 2
              }}
            >
              <Typography
                sx={{
                  borderRadius: "5px",
                  fontSize: "20px",
                  fontWeight: 500,
                  ml:2,
                }}
                fullWidth
              >
                {employeeDetails.name} Profile
              </Typography>
              <IconButton
                aria-label="edit"
                component="span"
                size="small"
                sx={{
                  mr:2,
                }}
                onClick={handleEdit}
              >
                <EditIcon />
              </IconButton>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
              sx={{
                display: userRole === 'employee' || userRole === 'client' ? 'flex' : 'none',
              }}
            >
              <Avatar
                onClick={handleEditImage}
                alt={employeeDetails.name}
                src={
                  newImage
                    ? URL.createObjectURL(newImage)
                    : employeeDetails.image ||
                    "https://cdn.pixabay.com/photo/2017/02/23/13/05/avatar-2092113_1280.png"
                }
                sx={{ width: 100, height: 100, cursor: 'pointer' }}
              />
              <FormControl sx={{ position: "absolute", zIndex: -1 }}>
                <Input
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  onChange={handleImageChange}
                />
              </FormControl>
            </Box>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              width={"100%"}
              sx={{ p: "10px" }}
            >
              <Grid item xs={6} textAlign="left" sx={{ display: userRole === 'employee' ? 'flex' : 'none', alignItems: 'center' }}>
                <Typography variant="h5" fontSize={"16px"}>
                  Employee ID
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="left" sx={{ display: userRole === 'employee' ? 'flex' : 'none', alignItems: 'center' }}>
                <TextField value={employeeDetails.emp_id} fullWidth disabled />
              </Grid>

              <Grid item xs={6} textAlign="left">
                <Typography variant="h5" fontSize={"16px"}>
                  User Name
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="left">
                <TextField
                  value={employeeDetails.name}
                  variant="standard"
                  fullWidth
                  onChange={handleUserNameChange}
                  disabled={editMode}
                />
              </Grid>

              <Grid item xs={6} textAlign="left">
                <Typography variant="h5" fontSize={"16px"}>
                  Phone Number
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="left">
                <TextField
                  value={employeeDetails.phone_no}
                  variant="standard"
                  fullWidth
                  onChange={handlePhoneNumberChange}
                  disabled={editMode}
                />
              </Grid>

              <Grid item xs={6} textAlign="left">
                <Typography variant="h5" fontSize={"16px"}>
                  Email
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="left">
                <TextField
                  value={employeeDetails.email}
                  variant="standard"
                  fullWidth
                  onChange={handleUserEmailChange}
                  disabled={editMode}
                />
              </Grid>

              <Grid item xs={6} textAlign="left" sx={{ display: userRole === 'employee' ? 'flex' : 'none', alignItems: 'center' }}>
                <Typography variant="h5" fontSize={"16px"}>
                  Role
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="left" sx={{ display: userRole === 'employee' ? 'flex' : 'none', alignItems: 'center' }}>
                <TextField
                  value={employeeDetails.role}
                  variant="standard"
                  fullWidth
                  onChange={handleRoleChange}
                  disabled={editMode}
                />
              </Grid>

              <Grid item xs={6} textAlign="left">
                <Typography variant="h5" fontSize={"16px"}>
                  Password
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="left">
                <TextField
                  type={showPassword ? "text" : "password"}
                  value={employeeDetails.password}
                  variant="standard"
                  fullWidth
                  onChange={handleUserPasswordChange}
                  disabled={editMode}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            
            <DialogActions sx={{ justifyContent: "center", mt: 1 }}>
              <Button
                onClick={handleEditSave}
                disabled={editMode}
                sx={{
                  bgcolor: "#2C2F3D",
                  width: "192px",
                  color: "white",
                  borderRadius: 50,
                  mb: 1,
                  "&:hover": {
                    bgcolor: "#2C2F3D",
                    color: "white",
                  },
                }}
              >
                Save
              </Button>
            </DialogActions>
          </>
        ) : (
          <Typography >
            Loading...
          </Typography>
        )}
      </DialogContent>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ProfileForm;
