// material-ui
import { useTheme } from '@mui/material/styles';
import logo from '../../assets/HnHLogo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */
    <>
      {/* <img src={logo} alt='logo' style={{width: '100%'}}/> */}
      <svg width="94" height="39" viewBox="0 0 94 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.15371 18.3096C7.03195 19.407 5.9102 20.5044 4.78844 21.6099V32.2341L0.537109 30.6653V6.40918L4.78844 7.97802V17.0334C5.9102 17.4561 7.03195 17.8869 8.15371 18.3096Z" fill="#EF3733"/>
      <path d="M13.4056 38.6439C11.9912 38.1074 10.5687 37.5791 9.1543 37.0426C9.1543 24.695 9.1543 12.3475 9.1543 0L13.4056 1.59323C13.4056 13.9408 13.4056 26.2883 13.4056 38.6439Z" fill="#EF3733"/>
      <path d="M21.9071 7.98193V32.2299L17.6558 30.661V21.6138C16.5747 21.2155 15.4854 20.8172 14.4043 20.427C15.4854 19.2971 16.5747 18.1591 17.6558 17.0292V6.41309L21.9071 7.98193Z" fill="#EF3733"/>
      <path d="M42.2068 20.3324V29.0788H40.2071V25.7136H32.9157V29.0788H30.916V20.3324H32.9157V23.6895H40.2071V20.3242H42.2068V20.3324Z" fill="#D9D9D9"/>
      <path d="M46.2809 22.3486V23.6898H55.2469V25.7057H46.2809V27.0551H55.2469V29.071H44.2812V20.3164H55.2469V22.3323H46.2809V22.3486Z" fill="#D9D9D9"/>
      <path d="M68.4213 29.0954H65.9664L59.1384 22.6412V29.0873H57.1387V20.3327H59.6179L66.4216 26.7625V20.3327V20.3164H68.4213V20.3327V29.0954Z" fill="#D9D9D9"/>
      <path d="M81.7788 29.0866H79.2832L77.3242 26.453H72.4958V29.0785H70.4961V20.332H78.7468C80.4131 20.332 81.7625 21.6977 81.7625 23.3884C81.7625 24.7378 80.8927 25.8839 79.6978 26.2985L81.7788 29.0866ZM72.4958 24.4289H78.7305C79.2995 24.4289 79.7628 23.9574 79.7628 23.3803C79.7628 22.8113 79.3076 22.3479 78.7386 22.3479H72.4876V24.4289H72.4958Z" fill="#D9D9D9"/>
      <path d="M93.4656 20.3242V21.3322C93.4656 22.8523 92.856 24.2179 91.7586 25.1689C90.9701 25.8599 89.954 26.307 88.8322 26.4695V29.0788H86.8326V26.4695C85.7108 26.2988 84.6947 25.8517 83.9062 25.1689C82.8089 24.2179 82.1992 22.8523 82.1992 21.3322V20.3242H84.1989V21.3322C84.1989 22.267 84.5484 23.0636 85.2069 23.6407C85.8653 24.2179 86.8001 24.5268 87.8324 24.5268C88.8648 24.5268 89.7995 24.2097 90.458 23.6407C91.1164 23.0717 91.4659 22.267 91.4659 21.3322V20.3242H93.4656Z" fill="#D9D9D9"/>
      <path d="M41.9226 9.65559V18.1907H39.9717V14.9067H32.8591V18.1907H30.9082V9.65559H32.8591V12.9315H39.9717V9.64746H41.9226V9.65559Z" fill="#D9D9D9"/>
      <path d="M45.8981 11.6305V12.9393H54.6446V14.9064H45.8981V16.2233H54.6446V18.1904H43.9473V9.65527H54.6446V11.6224H45.8981V11.6305Z" fill="#D9D9D9"/>
      <path d="M67.4864 18.1982H65.0559L63.1457 15.6296H58.4392V18.1901H56.4883V9.66309H64.5276C66.1533 9.66309 67.4702 10.9962 67.4702 12.6382C67.4702 13.955 66.6248 15.0768 65.4542 15.4751L67.4864 18.1982ZM58.4392 13.6543H64.5195C65.0803 13.6543 65.5274 13.1991 65.5274 12.6301C65.5274 12.0773 65.0803 11.6221 64.5276 11.6221H58.4311V13.6543H58.4392Z" fill="#D9D9D9"/>
      <path d="M79.1128 11.6302H74.5851V18.1901H72.6341V11.6302H68.1309V9.66309H79.1128V11.6302Z" fill="#D9D9D9"/>
      <path d="M89.4581 12.4844L83.3372 16.2236H90.3848V18.1907H81.5814C81.2156 18.1907 80.8823 18.0769 80.606 17.8656C80.3377 17.6542 80.1426 17.3616 80.0613 17.0283C79.8988 16.3618 80.1995 15.6871 80.8173 15.3538L86.9383 11.6146H79.8906V9.64746H88.694C89.0598 9.64746 89.3931 9.76126 89.6695 9.9726C89.9377 10.184 90.1328 10.4766 90.2141 10.8099C90.3767 11.4683 90.0759 12.143 89.4581 12.4844Z" fill="#D9D9D9"/>
      <path d="M93.4644 16.0332V16.4559L93.2368 16.5859V17.5207L93.4644 17.6427V18.0654L91.9688 17.2362V16.8705L93.4644 16.0332ZM92.8629 17.3094V16.7892L92.3914 17.0493L92.8629 17.3094Z" fill="#D9D9D9"/>
      <path d="M93.4649 12.8662V13.3214L92.2781 14.5814H93.4649V14.9472H91.8555V14.492L93.0422 13.2401H91.8555V12.8743H93.4649V12.8662Z" fill="#D9D9D9"/>
      <path d="M92.6602 9.54785C92.8797 9.54785 93.0748 9.62915 93.2292 9.78359C93.3837 9.93804 93.4649 10.1331 93.4649 10.3445V11.6207H91.8555V10.3445C91.8555 10.1331 91.9367 9.92991 92.0912 9.78359C92.2375 9.62915 92.4407 9.54785 92.6602 9.54785ZM92.6602 9.91364C92.4245 9.91364 92.2294 10.1087 92.2294 10.3445V11.2549H93.0991V10.3445C93.091 10.1087 92.8959 9.91364 92.6602 9.91364Z" fill="#D9D9D9"/>
      </svg>

    </>
  );
};

export default Logo;
