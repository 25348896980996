// assets
import { ReactComponent as DocumentIcon } from '../icons/docs.svg'
import { ReactComponent as LeavesIcon } from '../icons/leaves.svg'
// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const actions = {
  id: 'actions',
  title: 'ACTIONS',
  type: 'group',
  children: [
    {
      id: 'clientactions-send-documents',
      title: 'Send documents',
      type: 'item',
      url: '/employees/actions/docs',
      icon: DocumentIcon
    },
    {
      id: 'clientactions-apply-leaves',
      title: 'Apply leaves',
      type: 'item',
      url: '/employees/actions/leave',
      icon: LeavesIcon
    }
  ]
};

export default actions;
