// assets
import { ReactComponent as DashboardIcon } from './icons/dashboardIcons.svg'
import { ReactComponent as NotificationIcon } from './icons/notificationIcon.svg'
// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const ecommerce = {
  id: 'menu',
  title: 'MENU',
  type: 'group',
  children: [
    {
      id: 'menu-dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icon: DashboardIcon
    },
    
  ]
};

export default ecommerce;
