// assets
import { ReactComponent as DocumentIcon } from '../icons/docs.svg'
import { ReactComponent as LeavesIcon } from '../icons/leaves.svg'
import { ReactComponent as DashboardOutlined } from '../icons/dashboardIcons.svg'
// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const SActions = {
  id: 'actions',
  title: 'ACTIONS',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icon: DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'superAdminActions-send-documents',
      title: 'Documents',
      type: 'item',
      url: '/superadmin/actions/docs',
      icon: DocumentIcon
    },
    {
      id: 'actions-leaves',
      title: 'Leaves',
      type: 'item',
      url: '/actions/leave',
      icon: LeavesIcon
    },
    {
      id: 'otheractions-docs-invoice',
      title: 'Maintain Staffs',
      type: 'item',
      url: '/actions/maintainstaffs',
      icon: DocumentIcon
    },
    {
      id: 'otheractions-leaves',
      title: 'Maintain Clients',
      type: 'item',
      url: '/actions/maintainclients',
      icon: LeavesIcon
    },
    {
      id: 'otheractions-admins',
      title: 'Maintain Admins',
      type: 'item',
      url: '/superadmin/actions/maintainadmins',
      icon: LeavesIcon
    },
  ]
};

export default SActions;
