// assets
import { ReactComponent as DocumentIcon } from '../icons/docs.svg'
import { ReactComponent as LeavesIcon } from '../icons/leaves.svg'
// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const CActions = {
  id: 'actions',
  title: 'ACTIONS',
  type: 'group',
  children: [
    {
      id: 'actions-send-documents',
      title: 'Documents',
      type: 'item',
      url: '/clients/actions/docs',
      icon: DocumentIcon
    },
  ]
};

export default CActions;
